import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    getDrawCursor,
    getRotationDrawCursor,
    removeCanvasListener,
    setCanvasMaxWidthAndHeight,
    setCanvasStateOnCreate,
    setObjectSelection,
} from '../CanvasHelper';
import 'fabric-history';

const fabric: any = window.fabric;
let canvas: any = null;
let imageWidth = 0;
let imageHeight = 0;
let shapeColor = '#000000';
let textColor = '#000000';
let index = 1;

interface CanvasDrawInterface {
    backgroundImage: string;
    canvasDimension: { width: number; height: number };
    // isMobilelandscape: boolean;
}

const CanvasDraw = ({ backgroundImage, canvasDimension }: CanvasDrawInterface, ref: any) => {
    const canvasZoom = useRef<number>(1);

    const [textBoxChooseCount, setTextBoxChooseCount] = useState<number>(0);
    const [currentTextBoxDefaultCordinate, setCurrentTextBoxDefaultCordinate] = useState<any>();
    const [drawcanvasfromDraft, setDrawcanvasfromDraft] = useState(false);

    let initialEraserBrushSize = 15;
    useImperativeHandle(ref, () => ({
        handlePencil,
        handleEraser,
        handleErasorSize,
        handlePencilColor,
        handleTextColor,
        handleShapeColor,
        handleHighlight,
        handleRect,
        handleTextBox,
        storeCanvasState,
        storeCanvasData,
        undoCanvas,
        handleCircle,
        handleLine,
    }));

    useEffect(() => {
        fabric.Object.prototype.selectable = false;
        canvas = new fabric.Canvas('canvas', {
            isDrawingMode: false,
            selection: false,
            hoverCursor: 'crosshair',
        });

        canvas.defaultCursor = 'crosshair';
        setCanvasMaxWidthAndHeight(canvas);
        const canvasData = localStorage.getItem('canvasData');

        if (canvasData && JSON.parse(canvasData) && JSON.parse(canvasData).backgroundImage) {
            canvas.loadFromJSON(JSON.parse(canvasData));
            setDrawcanvasfromDraft(true);
            setTimeout(() => {
                handleResize();
            }, 0);
        } else {
            fabric.Image.fromURL(
                backgroundImage,
                function (image: any) {
                    imageWidth = image.width;
                    imageHeight = image.height;

                    canvas.setBackgroundImage(image, canvas.renderAll.bind(canvas), {
                        erasable: false,
                        backgroundImageStretch: false,
                    });
                    canvas.setDimensions({
                        width: imageWidth,
                        height: imageHeight,
                    });
                    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
                    canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
                    canvas.on('path:created', function (opt: any) {
                        opt.path.globalCompositeOperation = 'source-over';
                        canvas.requestRenderAll();
                    });
                    canvas.freeDrawingBrush.width = 3;
                    canvas.freeDrawingBrush.fillStyle = '';
                    handleResize();
                    canvas.historyNextState = JSON.stringify(canvas);
                },
                { crossOrigin: 'Anonymous' },
            );
        }

        fabric.Object.prototype.transparentCorners = false;

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);

        function onKeyup(e: any) {
            if (e.key === 'Delete') {
                canvas.remove(canvas.getActiveObject());
            }
        }
        window.addEventListener('keyup', onKeyup);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
        };
    }, [backgroundImage]);

    // useEffect(() => {
    //     handleResize();
    // }, [isMobilelandscape]);

    const undoCanvas = () => {
        if (drawcanvasfromDraft) {
            if (canvas.historyUndo.length < 2) {
                canvas.remove(...canvas.getObjects());
                canvas.historyUndo = [];
            } else canvas.undo();
        } else canvas.undo();
    };

    const handleResize = () => {
        canvas.setDimensions({
            width: imageWidth,
            height: imageHeight,
        });
        canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        setCanvasMaxWidthAndHeight(canvas);
        const outerCanvasContainer: any = document.getElementById('fabric-canvas-wrapper');
        if (!outerCanvasContainer) return;
        const containerWidth = outerCanvasContainer.clientWidth;
        const containerHeight = outerCanvasContainer.clientHeight;
        const zoom1 = containerWidth / imageWidth;
        const zoom2 = containerHeight / imageHeight;
        const zoom = Math.min(zoom1, zoom2);
        canvasZoom.current = zoom;
        canvas.setDimensions({
            width: imageWidth * zoom,
            height: imageHeight * zoom,
        });
        canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
        if (canvas.freeDrawingBrush._isErasing !== undefined) {
            // change eraser cursor size
            const currentEraserSize = canvas.freeDrawingBrush.width;
            defineEraserCursorSize(currentEraserSize, canvasZoom.current);
        }
    };

    const handleTextBox = () => {
        fabric.Object.prototype.selectable = true;
        setObjectSelection(canvas);
        removeCanvasListener(canvas);
        startAddText(canvas);
        setCanvasStateOnCreate(canvas);
    };

    const handleTextColor = (color: any) => {
        let obj = canvas.getActiveObject();
        if (obj) {
            textColor = color;
            obj.setSelectionStyles({ fill: color });
            canvas.renderAll();
        }
    };

    function startAddText(canvas: any) {
        fabric.Object.prototype.controls.mtr.cursorStyle = `url(${getRotationDrawCursor()}) 10 10,crosshair`;
        setTextBoxChooseCount((prev) => prev + 5);
        let x = textBoxChooseCount < 1 ? 100 : currentTextBoxDefaultCordinate.x;
        let y = textBoxChooseCount < 1 ? 100 : currentTextBoxDefaultCordinate.y;
        // let x = textBoxChooseCount < 1 ? canvas.width / 2 : currentTextBoxDefaultCordinate.x;
        // let y = textBoxChooseCount < 1 ? canvas.height / 2 : currentTextBoxDefaultCordinate.y;
        setCurrentTextBoxDefaultCordinate({ x: x + 10, y: y + 10 });

        const fontSize = 80;

        const drawInstance = new fabric.IText('Enter text...', {
            fill: textColor,
            fontSize: fontSize,
            erasable: true,
            left: x,
            top: y,
            startSelection: 0,
            endSelection: 0,
            textAlign: 'center',
        });

        canvas.add(drawInstance);
        canvas.setActiveObject(drawInstance);
        drawInstance.enterEditing();
        drawInstance.selectAll();
        drawInstance.on('click', function () {});
        removeCanvasListener(canvas);
        canvas.selection = true;
    }

    const handlePencil = () => {
        setObjectSelection(canvas);
        removeCanvasListener(canvas);
        canvas.defaultCursor = 'crosshair';
        canvas.freeDrawingCursor = 'crosshair';
        canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
        canvas.isDrawingMode = true;
        canvas.selectable = false;
        canvas.on('path:created', function (opt: any) {
            opt.path.globalCompositeOperation = 'source-over';
            canvas.requestRenderAll();
        });
        canvas.freeDrawingBrush.width = 3;
        canvas.freeDrawingBrush.fillStyle = '';
    };

    const handleHighlight = () => {
        removeCanvasListener(canvas);
        canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
        canvas.isDrawingMode = true;
        canvas.selectable = false;
        canvas.defaultCursor = 'crosshair';
        canvas.freeDrawingCursor = 'crosshair';
        canvas.on('path:created', function (opt: any) {
            opt.path.globalCompositeOperation = 'source-over';
            canvas.requestRenderAll();
        });

        canvas.freeDrawingBrush.width = 30;
        canvas.freeDrawingBrush.color = '';
        canvas.freeDrawingBrush.fillStyle = '';
    };

    const handlePencilColor = (color: string) => {
        canvas.freeDrawingBrush.color = color;
    };

    const handleShapeColor = (color: string) => {
        canvas.freeDrawingBrush.color = color;
        shapeColor = color;
    };

    const handleErasorSize = (size: number) => {
        canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
        canvas.freeDrawingBrush.width = size;
        defineEraserCursorSize(size, canvasZoom.current);
    };

    const defineEraserCursorSize = (eraserSize: number, zoom: number) => {
        const eraserOutlineSize = eraserSize * zoom;
        canvas.freeDrawingCursor = `url(${getDrawCursor(eraserOutlineSize)}) ${eraserOutlineSize / 2} ${
            eraserOutlineSize / 2
        }, crosshair`;
    };

    const removeTextBoxFocus = () => {
        if (!canvas.getActiveObject()) {
            return;
        }

        if (canvas.getActiveObject().type !== 'textbox' || !canvas.getActiveObject().isEditing) {
            let activeGroup = canvas.getActiveObjects();

            if (activeGroup) {
                canvas.discardActiveObject();
                canvas.selection = false;
                handleResize();
            }
        }
    };

    const handleEraser = () => {
        setObjectSelection(canvas);
        removeCanvasListener(canvas);
        removeTextBoxFocus();
        canvas.selection = false;
        canvas.isDrawingMode = true;
        canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
        canvas.freeDrawingBrush.width = initialEraserBrushSize;
        defineEraserCursorSize(initialEraserBrushSize, 1);

        canvas.on('mouse:up', function (o: any) {
            const historyNextState = canvas.historyNextState;
            canvas.historyUndo.push(historyNextState);
            canvas.historyNextState = JSON.stringify(canvas);
        });
    };

    const handleRect = () => {
        setObjectSelection(canvas);
        removeCanvasListener(canvas);
        canvas.isDrawingMode = false;
        canvas.selection = false;
        canvas.defaultCursor = 'crosshair';
        var rect: any, origX: any, origY: any;
        let isDown: boolean = false;
        canvas.on('mouse:down', function (o: any) {
            canvas.isDrawingMode = false;
            isDown = true;
            var pointer = canvas.getPointer(o.e);
            origX = pointer.x;
            origY = pointer.y;
            rect = new fabric.Rect({
                id: index,
                left: origX,
                top: origY,
                originX: 'left',
                originY: 'top',
                width: pointer.x - origX,
                height: pointer.y - origY,
                angle: 0,
                fill: '',
                stroke: shapeColor,
                transparentCorners: false,
                strokeWidth: 4,
                selectable: false,
            });
            rect.hoverCursor = 'crosshair';
            canvas.add(rect);
        });

        canvas.on('mouse:move', function (o: any) {
            if (!isDown) return;
            var pointer = canvas.getPointer(o.e);

            if (origX > pointer.x) {
                rect.set({ left: Math.abs(pointer.x) });
            }
            if (origY > pointer.y) {
                rect.set({ top: Math.abs(pointer.y) });
            }
            if (rect) {
                rect.set({ width: Math.abs(origX - pointer.x) });
                rect.set({ height: Math.abs(origY - pointer.y) });
            }
            rect.hoverCursor = 'crosshair';
            canvas.renderAll();
        });

        canvas.on('mouse:up', function (o: any) {
            isDown = false;
            canvas.isDrawingMode = false;
            canvas.historyNextState = JSON.stringify(canvas);
        });
    };

    const handleCircle = () => {
        setObjectSelection(canvas);
        removeCanvasListener(canvas);
        canvas.isDrawingMode = false;
        canvas.selection = false;
        canvas.defaultCursor = 'crosshair';
        var circle: any, origX: any, origY: any;
        let isDown: boolean = false;
        canvas.on('mouse:down', function (o: any) {
            isDown = true;
            var pointer = canvas.getPointer(o.e);
            origX = pointer.x;
            origY = pointer.y;
            circle = new fabric.Circle({
                left: origX,
                top: origY,
                radius: pointer.x - origX,
                angle: 0,
                fill: '',
                stroke: shapeColor,
                strokeWidth: 3,
            });
            canvas.add(circle);
        });

        canvas.on('mouse:move', function (o: any) {
            if (!isDown) return;
            var pointer = canvas.getPointer(o.e);
            var radius = Math.max(Math.abs(origY - pointer.y), Math.abs(origX - pointer.x)) / 2;
            if (radius > circle.strokeWidth) {
                radius -= circle.strokeWidth / 2;
            }
            circle.set({ radius: radius });
            if (origX > pointer.x) {
                circle.set({ originX: 'right' });
            } else {
                circle.set({ originX: 'left' });
            }
            if (origY > pointer.y) {
                circle.set({ originY: 'bottom' });
            } else {
                circle.set({ originY: 'top' });
            }
            canvas.renderAll();
        });

        canvas.on('mouse:up', function () {
            canvas.historyNextState = JSON.stringify(canvas);
            isDown = false;
        });
    };

    const handleLine = () => {
        setObjectSelection(canvas);
        removeCanvasListener(canvas);
        canvas.isDrawingMode = false;
        canvas.selection = false;
        canvas.defaultCursor = 'crosshair';
        var line: any, origX: any, origY: any;
        let isDown: boolean = false;

        canvas.on('mouse:down', function (o: any) {
            isDown = true;
            var pointer = canvas.getPointer(o.e);
            var points = [pointer.x, pointer.y, pointer.x, pointer.y];
            line = new fabric.Line(points, {
                strokeWidth: 4,
                fill: 'black',
                stroke: shapeColor,
                originX: 'center',
                originY: 'center',
            });
            canvas.add(line);
        });

        canvas.on('mouse:move', function (o: any) {
            if (!isDown) return;
            var pointer = canvas.getPointer(o.e);
            line.set({ x2: pointer.x, y2: pointer.y });
            canvas.renderAll();
        });

        canvas.on('mouse:up', function () {
            canvas.historyNextState = JSON.stringify(canvas);
            isDown = false;
        });
    };

    const storeCanvasState = () => {
        const canvasObject = canvas.toJSON();
        localStorage.setItem('canvasData', JSON.stringify(canvasObject));
    };

    const storeCanvasData = () => {
        canvas.setDimensions({
            width: imageWidth,
            height: imageHeight,
        });
        canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        const canvasDataUrl = canvas.toDataURL({});
        localStorage.setItem('canvasDataUrl', canvasDataUrl);
        handleResize();
    };

    return (
        <div className="main_div">
            {/* <div className="main_div" style={{ width: '100%' }}> */}
            <div
                id="fabric-canvas-wrapper"
                className="fabric-canvas-wrapper"
                style={{ width: `${canvasDimension.width}px`, height: `${canvasDimension.height}px` }}
                // style={{maxWidth: `${canvasMaxWidth}`,maxHeight: `${canvasMaxHeight}`}}
            >
                <canvas id="canvas"></canvas>
            </div>
        </div>
    );
};

export default forwardRef(CanvasDraw);
